.ConfirmResume {
	&__main {
		width: 100%;
	}
	&__container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		&__box {
			flex-grow: 1;
		}
	}
}
