@use 'sass:map';

.terms-and-conditions-modal-html-container {
	max-height: 55vh !important;
	@media screen and (max-width: map.get($grid-breakpoints, 'md')) {
		max-height: 75vh !important;
	}

	text-align: left !important;
	font-size: 1rem !important;
	.content {
		.terms-and-conditions-header {
			font-weight: bold;
		}

		.terms-and-conditions-table {
			table-layout: auto;
			width: 100%;

			tr.terms-and-conditions-header > td {
				background-color: Canvas;
				word-break: keep-all;
				border: none;
				padding-left: 0;
				padding-right: 0;
			}

			th,
			td {
				padding: 0.8rem;
				border: 1px solid rgba($color: black, $alpha: 0.4);
				border-collapse: collapse;
			}

			tr {
				> th {
					background-color: #d9e2f3;
				}
				> :is(th, td) {
					word-break: keep-all;
				}
			}

			td {
				p.service-attribute {
					margin-bottom: 0.2rem;
				}
				.service-attribute-name {
					font-weight: bold;
				}
			}

			&.remarks-table {
				tr {
					&:nth-child(even) {
						background-color: #d9e2f3;
					}
					> th {
						text-transform: uppercase;
					}
					> td:first-child {
						font-weight: bold;
					}
				}
			}
		}
	}
	.confirm {
		text-align: center;
	}
}

.terms-and-conditions-modal-popup {
	width: 68vw !important;
	@media screen and (max-width: map.get($grid-breakpoints, 'xxl')) {
		width: 75vw !important;
	}
	@media screen and (max-width: map.get($grid-breakpoints, 'lg')) {
		width: 95vw !important;
	}
}
