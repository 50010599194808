@use 'sass:map';

.table-action-button {
	border: none;
	background: none;
	&:hover {
		border: none;
		background: none;
	}
}
